import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import { ItemFormValues } from "../../../app/models/item";
import { v4 as uuid } from "uuid";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import { combineValidators, isRequired } from "revalidate";
import PhotoUploadWidget from "../../../app/common/photoUpload/PhotoUploadWidget";
import { IPhoto } from "../../../app/models/profile";
import { RootStoreContext } from "../../../app/stores/rootStore";

const validate = combineValidators({
  title: isRequired({ message: "The Item Title is required." }),
  description: isRequired({ message: "The Item Title is required." }),
});

interface DetailParams {
  id: string;
}

const ItemForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createItem,
    editItem,
    submitting,
    loadItem,
    uploadItemPhoto,
    deletePhoto,
    setMainPhoto,
  } = rootStore.itemStore;

  const [formItem, setItem] = useState(new ItemFormValues());
  const [loading, setLoading] = useState(false);
  const [photoBlobs, setPhotoBlobs] = useState<Blob[]>([]);
  const [target, setTarget] = useState<string | undefined>(undefined);
  const [deleteTarget, setDetleteTarget] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadItem(match.params.id)
        .then((item) => {
          setItem(new ItemFormValues(item));
        })
        .finally(() => setLoading(false));
    }
  }, [loadItem, match.params.id]);

  const handleFinalFormSubmit = (values: any) => {
    const { ...item } = values;
    if (!item.id) {
      let newItem = {
        ...item,
        id: uuid(),
      };
      createItem(newItem, photoBlobs);
    } else {
      editItem(item);
      history.push(`/items/${formItem.id}`);
    }
  };

  const handleUploadImage = async (photo: Blob) => {
    if (formItem!.id) {
      await uploadItemPhoto(formItem.id, photo);

      let refreshedItem = await loadItem(formItem.id);
      setItem(refreshedItem);
    } else {
      let blobs: Blob[] = [];
      blobs.push(photo);

      photoBlobs.forEach((item) => {
        blobs.push(item);
      });

      setPhotoBlobs(blobs);
    }
  };

  const handleDeletePhoto = async (photo: IPhoto) => {
    await deletePhoto(photo);
    let refreshedItem = await loadItem(formItem.id!);
    setItem(refreshedItem);
  };

  const handleSetMainPhoto = async (photo: IPhoto) => {
    await setMainPhoto(photo);
    let refreshedItem = await loadItem(formItem.id!);
    setItem(refreshedItem);
  };

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Item {formItem!.id ? "Update" : "Insertion"}</Header>
          </Grid.Column>
        </Grid.Row>
        {
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment placeholder>
                <PhotoUploadWidget
                  loading={false}
                  uploadPhoto={handleUploadImage}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column width={16}>
            <Card.Group>
              {formItem!.id
                ? formItem!.photos!.map((photo, i) => (
                    <Card
                      key={i}
                      style={{
                        maxHeight: "170px",
                        maxWidth: "100px",
                        overflow: "hidden",
                      }}
                    >
                      <Card.Header>
                        <Image
                          src={photo.url}
                          style={{
                            maxHeight: "100px",
                            maxWidth: "100px",
                            overflow: "hidden",
                          }}
                        />
                      </Card.Header>
                      <Card.Content
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          overflow: "hidden",
                        }}
                      >
                        <Button.Group fluid widths={2}>
                          <Button
                            name={photo.id}
                            onClick={async (e) => {
                              handleSetMainPhoto(photo);
                              setTarget(e.currentTarget.name);
                            }}
                            disabled={photo.isMain}
                            loading={loading && target === photo.id}
                            basic
                            positive
                            icon="pin"
                          />
                          <Button
                            name={photo.id}
                            disabled={photo.isMain}
                            onClick={(e) => {
                              handleDeletePhoto(photo);
                              setDetleteTarget(e.currentTarget.name);
                            }}
                            loading={loading && deleteTarget === photo.id}
                            basic
                            negative
                            icon="trash"
                          />
                        </Button.Group>
                      </Card.Content>
                    </Card>
                  ))
                : photoBlobs.map((photo, i) => (
                    <Card
                      key={i}
                      style={{
                        maxHeight: "100px",
                        maxWidth: "100px",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        src={URL.createObjectURL(photo)}
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          overflow: "hidden",
                        }}
                      />
                    </Card>
                  ))}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <FinalForm
              validate={validate}
              initialValues={formItem}
              onSubmit={handleFinalFormSubmit}
              render={({ handleSubmit, invalid, pristine }) => (
                <Form onSubmit={handleSubmit} loading={loading}>
                  <Field
                    name="title"
                    placeholder="Title"
                    value={formItem.title}
                    component={TextInput}
                  />
                  <Field
                    name="description"
                    placeholder="Description"
                    value={formItem.description}
                    component={TextInput}
                  />
                  {formItem!.id && (
                    <Field
                      name="available"
                      value={formItem!.available}
                      component={"select"}
                      style={{ marginBottom: 30 }}
                    >
                      <option value="true">Available</option>
                      <option value="false">Unavailable</option>
                    </Field>
                  )}
                  <Button
                    type="submit"
                    content="Submit"
                    positive
                    floated="right"
                    disabled={loading || invalid || pristine}
                    loading={submitting}
                  />
                  <Button
                    onClick={
                      formItem.id
                        ? () => history.push(`/items/${formItem.id}`)
                        : () => history.push("/items")
                    }
                    disabled={loading}
                    floated="right"
                    type="button"
                    content="Cancel"
                  />
                </Form>
              )}
            ></FinalForm>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(ItemForm);
