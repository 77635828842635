import React, { useContext } from "react";
import { Button, Container, Grid, Header, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import LoginForm from "../user/LoginForm";
import RegisterForm from "../user/RegisterForm";

const HomePage = () => {
  const token = window.localStorage.getItem('jwt');
  const rootStore = useContext(RootStoreContext);
  const { user, isLoggedIn } = rootStore.userStore;
  const { openModal } = rootStore.modalStore;

  return (
    <Grid className="masthead" style={{ margin: "0px", padding: "0px" }}>
      <Grid.Row style={{ margin: "0px", padding: "0px" }}>
        <Grid.Column width={16}>
          <Image
            centered
            size="large"
            src="/assets/ExycleIconTransparent.png"
            alt="Exycle Logo"
            className="homeLogoImg"
          />
          <Header
            as="h1"
            inverted
            content="EXYCLE"
            className="myFont"
            textAlign="center"
            style={{
              fontSize: "6rem",
              color: "#00422E",
              fontWeight: 800,
              margin: 0,
              padding: 0,
            }}
          />
          <Header
            as="h4"
            inverted
            content="Maybe your trash is someone's treasure."
            className="myFont"
            size="small"
            textAlign="center"
            style={{
              fontSize: "24px",
              color: "#00A874",
              fontWeight: 800,
              margin: 0,
              padding: 0,
            }}
          />
          {isLoggedIn && user && token ? (
            <Container textAlign="center" className="loginContainer">
              <Header
                as="h2"
                inverted
                content={`Welcome back ${user.username}`}
              />
              <Button as={Link} to="/items" size="huge">
                Take me to the stuff!
              </Button>
            </Container>
          ) : (
            <Container textAlign="center" className="loginContainer">
              <Button
                onClick={() => openModal(<LoginForm />)}
                size="huge"
                inverted
              >
                Login
              </Button>
              <Button
                onClick={() => openModal(<RegisterForm />)}
                size="huge"
                inverted
              >
                Register
              </Button>
            </Container>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header as="h2" inverted content={`We are still on BETA version, data will be removed periodically.`} />
          <Header as="h3" inverted content={`For further information please contact:`} />
          <a id="supportEmailLink" href={`mailto:support@exycle.com`}>support@exycle.com</a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default HomePage;
