import { configure } from "mobx";
import { createContext } from "react";
import CommonStore from "./commonStore";
import ItemStore from "./itemStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";
import ProfileStore from "./profileStore";

configure({ enforceActions: "always" });

export class RootStore {
  itemStore: ItemStore;
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  profileStore: ProfileStore;

  constructor() {
    this.itemStore = new ItemStore(this);
    this.commonStore = new CommonStore(this);
    this.userStore = new UserStore(this);
    this.modalStore = new ModalStore(this);
    this.profileStore = new ProfileStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
