import { IPhoto } from "./profile";
import { IUser } from "./user";

export interface IItem {
  id: string;
  title: string;
  description: string;
  dateAdded: Date;
  available: boolean;
  image: string;
  user?: IUser;
  photos?: IPhoto[];
  comments: IComment[];
}

export interface IComment {
  id: string;
  createdAt: Date;
  body: string;
  username: string;
  displayName: string;
  image: string;
}

export interface IItemFormValues extends Partial<IItem> {}

export class ItemFormValues implements IItemFormValues {
  id?: string = undefined;
  available?: boolean;
  title: string = "";
  description: string = "";
  image?: string = "";
  user?: IUser;
  photos?: IPhoto[];

  constructor(init?: IItem) {
    Object.assign(this, init);
  }
}

export interface IItemPagedList {
  items: IItem[];
  itemsCount: number;
}