import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Container, Menu, Image, Dropdown, Label } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

const Navbar: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user, logout } = rootStore.userStore;
  return (
    <Menu fixed="top" inverted>
      <Container>
      <Menu.Item name="Beta" as={Label} content="BETA" />
        <Menu.Item fitted style={{ width: "200px" }} as={NavLink} to="/">
          <Image fluid src="/assets/ELHW.png" alt="logo" className="logoImg" />
        </Menu.Item>
        <Menu.Item name="Items" as={NavLink} to="/items" />
        <Menu.Item>
          <Button
            positive
            content="Create Item"
            as={NavLink}
            to="/createItem"
          />
        </Menu.Item>
        {user && (
          <Menu.Item position="right">
            <Image
              avatar
              spaced="right"
              src={user.image || "/assets/user.png"}
            />
            <Dropdown pointing="top left" text={user.username}>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`/profile/${user.username}`}
                  text="My profile"
                  icon="user"
                />
                <Dropdown.Item text="Logout" icon="power" onClick={logout} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )}
      </Container>
    </Menu>
  );
};

export default observer(Navbar);
