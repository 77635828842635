import { Dot } from "pure-react-carousel";
import React from "react";
import { Button, Container } from "semantic-ui-react";

interface IProps {
  slides: number;
}

const CustomDotGroup: React.FC<IProps> = ({ slides }) => {
  return (
    <Container textAlign="center">
      <Button.Group size={"mini"}>
        {Array(slides)
          .fill(1)
          .map((_, slide) => (
            <Button as={Dot} key={slide} icon="circle" slide={slide} />
          ))}
      </Button.Group>
    </Container>
  );
};

export default CustomDotGroup;
