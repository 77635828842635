import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Card } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ItemListItem from "./ItemListItem";

const ItemList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getItems } = rootStore.itemStore;

  return (
    <Card.Group itemsPerRow={3}>
      {getItems.map((item) => (
        <ItemListItem key={item.id} item={item} />
      ))}
    </Card.Group>
  );
};

export default observer(ItemList);
