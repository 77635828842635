import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import ItemDetailedChat from "./ItemDetailedChat";
import ItemDetailedHeader from "./ItemDetailedHeader";
import ItemDetailedinfo from "./ItemDetailedinfo";
import ItemDetailedSidebar from "./ItemDetailedSidebar";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface DetailParams {
  id: string;
}

const ItemDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { item, loadItem, loadingInitial } = rootStore.itemStore;
  const { isCurrentUserForItem } = rootStore.itemStore;

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    loadItem(match.params.id).then(() => setInitialLoad(false));
  }, [loadItem, match.params.id, history]);

  if (loadingInitial) {
    return <LoadingComponent content="Loading Item..." />;
  }

  if (!item) {
    return <h2>Item Not Found.</h2>;
  }

  return (
    <Grid>
      <Grid.Column width={10}>
        <ItemDetailedHeader
          item={item}
          isCurrentUserForItem={isCurrentUserForItem}
        />
        <ItemDetailedinfo item={item} />
        {!initialLoad && <ItemDetailedChat />}
      </Grid.Column>
      <Grid.Column width={6}>
        <ItemDetailedSidebar />
      </Grid.Column>
    </Grid>
  );
};

export default observer(ItemDetails);
