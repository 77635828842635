import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, Header, Icon } from "semantic-ui-react";
import ItemList from "./ItemList";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import InfiniteScroll from "react-infinite-scroller";
import ItemListItemPlaceholder from "./ItemListItemPlaceholder";
import ItemFilters from "./ItemFilters";

const ItemDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadItems,
    loadingInitial,
    setPage,
    page,
    totalPages,
  } = rootStore.itemStore;

  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
    setLoadingNext(true);
    setPage(page + 1);
    loadItems().then(() => setLoadingNext(false));
  };

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Grid>
      <Grid.Column width={12}>
        {loadingInitial && page === 0 ? (
          <ItemListItemPlaceholder />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleGetNext}
            hasMore={!loadingNext && page + 1 < totalPages}
            initialLoad={false}
          >
            <ItemList />
            {!loadingNext && page + 1 < totalPages && (
              <Divider horizontal>Scroll down for more Items</Divider>
            )}
            {!loadingNext && page + 1 === totalPages && (
              <Divider horizontal>End of list</Divider>
            )}

            {totalPages === 0 && (
               <Header as='h2' icon textAlign={"center"}>
               <Icon name='search' />
               No Items available
               <Header.Subheader>
                 Try to change the filtering or add a new Item.
               </Header.Subheader>
             </Header>
            )}
          </InfiniteScroll>
        )}
      </Grid.Column>
      <Grid.Column width={4}>
        <ItemFilters />
      </Grid.Column>
    </Grid>
  );
};

export default observer(ItemDashboard);
