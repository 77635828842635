import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  CardGroup,
  Tab,
  Card,
  Image,
  Button,
  TabProps,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { RootStoreContext } from "../../app/stores/rootStore";
import { runInAction } from "mobx";

const panes = [
  { menuItem: "Available Items", pane: { key: "available" } },
  { menuItem: "Unavailable Items", pane: { key: "unavailable" } },
  { menuItem: "All", pane: { key: "all" } },
];

const ProfileItems = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadUserItems,
    profile,
    loadingUserItems,
    userItems,
    isCurrentUser,
  } = rootStore.profileStore!;

  useEffect(() => {
    loadUserItems(profile!.username, "available");
  }, [loadUserItems, profile]);


  const handleDeleteItem = async (id: any,e: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
    await deleteItem(id, e);
    runInAction(() => {
      loadUserItems(profile!.username, "available");
    });
  }
  const { deleteItem } = rootStore.itemStore;

  const handleTabChange = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    let predicate;
    switch (data.activeIndex) {
      case 0:
        predicate = "available";
        break;
      case 1:
        predicate = "unavailable";
        break;
      default:
        //Default case will be for all the items.
        predicate = "all";
        break;
    }
    loadUserItems(profile!.username, predicate);
  };

  return (
    <Tab.Pane loading={loadingUserItems}>
      <Tab
        panes={panes}
        menu={{ secondary: true, pointing: true }}
        onTabChange={(e, data) => handleTabChange(e, data)}
      />
      <br />
      <CardGroup itemsPerRow={3}>
        {userItems.map((item) => (
          <Card key={item.id}>
            <Image
              src={item.photo ?? "/assets/giftBox.jpg"}
              wrapped
              ui={false}
              label={{
                ribbon: "right",
                content: item.available ? "Available" : "Unavailable",
                color: item.available ? "green" : "red",
              }}
              as={Link}
              to={`/items/${item.id}`}
            />
            <Card.Content>
              <Card.Header as={Link} to={`/items/${item.id}`}>
                {item.title}
              </Card.Header>
              <Card.Meta>
                <div>
                  Added in{" "}
                  {formatDistance(new Date(item.dateAdded), new Date())}
                </div>
              </Card.Meta>
              <Card.Description>{item.title}</Card.Description>
            </Card.Content>
            {isCurrentUser && (
              <Card.Content extra>
                <Button
                  compact
                  basic
                  color="green"
                  content="Edit"
                  icon="edit"
                  as={Link}
                  to={`/manage/${item.id}`}
                />
                <Button
                  compact
                  basic
                  color="red"
                  content="Remove"
                  icon="trash"
                  onClick={(e) => handleDeleteItem(item.id, e)}
                />
              </Card.Content>
            )}
          </Card>
        ))}
      </CardGroup>
    </Tab.Pane>
  );
};

export default observer(ProfileItems);
