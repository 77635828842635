import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Grid, Header, List, Tab } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import ProfileEditForm from "./ProfileEditForm";
import { format } from 'date-fns';

const ProfileDescription = () => {
  const rootStore = useContext(RootStoreContext);
  const { updateProfile, profile, isCurrentUser } = rootStore.profileStore;
  const [editMode, setEditMode] = useState(false);

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <Header
            floated="left"
            icon="user"
            content={`About ${profile!.username}`}
          />
          {isCurrentUser && (
            <Button
              floated="right"
              basic
              content={editMode ? "Cancel" : "Edit Profile"}
              onClick={() => setEditMode(!editMode)}
            />
          )}
        </Grid.Column>
        <Grid.Column width={16}>
          {editMode ? (
            <ProfileEditForm updateProfile={updateProfile} profile={profile!} />
          ) : (
            <List divided relaxed>
              <List.Item>
                <List.Icon size="large" name="book" verticalAlign="middle" />
                <List.Content>
                  <List.Header as="h3"> Bio: </List.Header>
                  <List.Description>{profile?.bio}</List.Description>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon size="large" name="marker" verticalAlign="middle" />
                <List.Content>
                  <List.Header as="h3"> Address: </List.Header>
                  <List.Description>
                    {" "}
                    {profile?.address1} {profile?.address2} {profile?.city}{" "}
                    {profile?.state} {profile?.zip} {profile?.country}
                  </List.Description>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon size="large" name="clock" verticalAlign="middle" />
                <List.Content>
                  <List.Header as="h3"> Member Since: </List.Header>
                  {/* <List.Description>{profile!.memberSince}</List.Description> */}
                  <List.Description>{format(new Date(profile!.memberSince), 'LLLL, do yyyy')}</List.Description>
                </List.Content>
              </List.Item>
            </List>
          )}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};

export default observer(ProfileDescription);
