import React from "react";
import { IItem } from "../../../app/models/item";
import {
  Card,
  Image,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";

const ItemListItem: React.FC<{ item: IItem }> = ({ item }) => {
  return (
    <Card>
      <Image
        alt={item.description || "giftBox" }
        src={item.photos?.find((p) => p.isMain)?.url || "/assets/giftBox.jpg"}
        wrapped
        ui={false}
        label={{
          ribbon: "right",
          content: item.available ? "Available" : "Unavailable",
          color: item.available ? "green" : "red",
        }}
        as={Link}
        to={`/items/${item.id}`}
      />
      <Card.Content>
        <Card.Header as={Link} to={`/items/${item.id}`}>
          {item.title}
        </Card.Header>
        <Card.Meta>
          <div>
            Added in {formatDistance(new Date(item.dateAdded), new Date())}
          </div>
          <div>
            {item.user?.city} {item.user?.state}
          </div>
        </Card.Meta>
        <Card.Description>{item.description}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ItemListItem;