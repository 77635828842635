import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Header, Menu } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ItemFilters = () => {
  const rootStore = useContext(RootStoreContext);
  const { predicate, setPredicate } = rootStore.itemStore;
  return (
    <Fragment>
      <Menu vertical size={"large"} style={{ width: "100%", marginTop: 50 }}>
        <Header icon={"filter"} attached color={"teal"} content={"Filters"} />
        <Menu.Item
          active={predicate.size === 0}
          onClick={() => setPredicate("all", "true")}
          color={"blue"}
          name={"all"}
          content={"All Items"}
        />
        <Menu.Item
          active={predicate.get("isAvailable") === "true"}
          onClick={() => setPredicate("isAvailable", "true")}
          color={"blue"}
          name={"available"}
          content={"Available Items"}
        />
        <Menu.Item
          active={predicate.get("isAvailable") === "false"}
          onClick={() => setPredicate("isAvailable", "false")}
          color={"blue"}
          name={"unavailable"}
          content={"Unavailable Items"}
        />
      </Menu>
    </Fragment>
  );
};

export default observer(ItemFilters);
