import axios, { AxiosResponse } from "axios";
import { IItem, IItemPagedList } from "../models/item";
import { history } from "../..";
import { toast } from "react-toastify";
import { IUser, IUserFormValues } from "../models/user";
import { IPhoto, IProfile } from "../models/profile";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network Error - Make Sure API is Running.");
  }

  const { status, data, config, headers } = error.response;

  if (status === 404) {
    history.push("/notfound");
  }

  if (
    status === 401 &&
    headers["www-authenticate"] ===
      'Bearer error="invalid_token", error_description="The token is expired"'
  ) {
    window.localStorage.removeItem("jwt");
    history.push("/");
    toast.info("Your session has expired, please login again");
  }

  if (
    status === 400 &&
    config.method === "get" &&
    data.errors.hasOwnProperty("id")
  ) {
    history.push("/notfound");
  }

  if (status === 500) {
    toast.error("Server error - check the terminal for more info!");
  }

  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios
      .post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(responseBody);
  },
};

const Items = {
  list: (params: URLSearchParams): Promise<IItemPagedList> =>
    axios.get("/items", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/items/${id}`),
  create: (item: IItem) => {
    return requests.post("/items", item);
  },
  update: (item: IItem) => requests.put(`/items/${item.id}`, item),
  delete: (id: string) => requests.del(`/items/${id}`),
  uploadItemPhoto: (itemId: string, photo: Blob): Promise<IPhoto> =>
    requests.postForm(`/items/${itemId}/photos`, photo),
  deleteItemPhoto: (itemId: string, photoId: string) =>
    requests.del(`/items/${itemId}/photos/${photoId}`),
  setMainItemPhoto: (itemId: string, photoId: string) =>
    requests.put(`/items/${itemId}/photos/${photoId}`, {}),
};

const User = {
  current: (): Promise<IUser> => requests.get("/users"),
  login: (user: IUserFormValues): Promise<IUser> =>
    requests.post("/users/login", user),
  register: (user: IUserFormValues): Promise<IUser> =>
    requests.post("/users/register", user),
  fbLogin: (accessToken: string) =>
    requests.post(`/users/facebook`, { accessToken }),
  refreshToken: (): Promise<IUser> => requests.post(`/users/refreshToken`, {}),
  verifyEmail: (token: string, email: string): Promise<void> =>
    requests.post(`/users/verifyEmail`, { token, email }),
  resendVerifyEmailConfirm: (email: string): Promise<void> =>
    requests.get(`/users/resendEmailVerification?email=${email}`),
};

const Profiles = {
  get: (username: string): Promise<IProfile> =>
    requests.get(`/profiles/${username}`),
  uploadUserPhoto: (username: string, photo: Blob): Promise<IPhoto> =>
    requests.postForm(`/profiles/${username}/photos`, photo),
  setMainUserPhoto: (username: string, id: string) =>
    requests.put(`/profiles/${username}/photos/${id}`, {}),
  deleteUserPhoto: (username: string, id: string) =>
    requests.del(`/profiles/${username}/photos/${id}`),
  updateProfile: (profile: Partial<IProfile>) =>
    requests.put(`/profiles`, profile),
  follow: (username: string) =>
    requests.post(`/profiles/${username}/follow`, {}),
  unfollow: (username: string) => requests.del(`/profiles/${username}/follow`),
  listFollowings: (username: string, predicate: string) =>
    requests.get(`/profiles/${username}/follow?predicate=${predicate}`),
  listItems: (username: string, predicate: string) =>
    requests.get(`/profiles/${username}/items?predicate=${predicate}`),
};

export default {
  Items,
  User,
  Profiles,
};
