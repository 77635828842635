import {
  computed,
  observable,
  action,
  runInAction,
  reaction,
  toJS,
} from "mobx";
import { SyntheticEvent } from "react";
import agent from "../api/agent";
import { IItem } from "../models/item";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { toast } from "react-toastify";
import { IPhoto } from "../models/profile";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

const LIMIT = 3;

export default class ItemStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.itemRegistry.clear();
        this.loadItems();
      }
    );
  }

  //A Place to store and handle the items. This one will provided a better list of items.
  @observable itemRegistry = new Map();
  @observable item: IItem | null = null;
  @observable loading = false;
  @observable.ref hubConnection: HubConnection | null = null;

  //Used to Load initial list of Items. If true will display a loading bar. submitting will be for when sending items to be updated/created or removed.
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable uploadingPhoto = false;
  @observable isCurrentUserForItem = false;
  @observable itemsCount = 0;
  @observable page = 0;
  @observable predicate = new Map();

  //This will point to the specific button that is deleting. Otherwise all the buttons will show deleting at the same time. same for other buttons with other operations.
  @observable target = "";

  @action setPredicate = (predicate: string, value: string) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page ? this.page * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.itemsCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @action createHubConnection = (itemId: string) => {
    if (!this.hubConnection) {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_CHAT_URL!, {
          accessTokenFactory: () => this.rootStore.commonStore.token!,
        })
        .configureLogging(LogLevel.Information)
        .build();

      this.hubConnection.on("ReceiveComment", (comment) => {
        runInAction(() => {
          this.item!.comments.push(comment);
        });
      });

      this.hubConnection.on("Send", (message) => {
        toast.info(message);
      });
    }

    if (this.hubConnection!.state === "Disconnected") {
      this.hubConnection
        .start()
        .then(() => {
          console.log(`Attempting to join group`);
          this.hubConnection!.invoke("AddToGroup", itemId);
        })
        .catch((error) =>
          console.log("Error establishing connection: ", error)
        );
    } else if (this.hubConnection!.state === "Connected") {
      this.hubConnection!.invoke("AddToGroup", itemId);
    }
  };

  @action stopHubConnection = () => {
    if (this.hubConnection?.state === "Connected") {
      this.hubConnection!.invoke("RemoveFromGroup", this.item!.id)
        .then(() => {
          this.hubConnection!.stop();
        })
        .then(() => console.log("Connection stopped"))
        .catch((err) => console.log(err));
    }
  };

  @action addComment = async (values: any) => {
    values.itemId = this.item!.id;
    try {
      await this.hubConnection!.invoke("SendComment", values);
    } catch (error) {
      console.log(error);
    }
  };

  @computed get getItems(): IItem[] {
    return Array.from(this.itemRegistry.values());
  }

  //Will insert the items comming from the API into the itemRegistry.
  @action loadItems = async () => {
    this.loadingInitial = true;
    try {
      const itemsPagedList = await agent.Items.list(this.axiosParams);
      const { items, itemsCount } = itemsPagedList;
      runInAction(() => {
        if (items) {
          for (let index = 0; index < items.length; index++) {
            const item = items[index];
            this.itemRegistry.set(item.id, item);
          }
        }
        this.itemsCount = itemsCount ?? 0;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false;
        console.log(error);
      });
    }
  };

  //This will get the item from the registry if exists otherwise it will call the API to see if exists persisted.
  @action loadItem = async (id: string) => {
    let item = this.itemRegistry.get(id);
    if (item) {
      this.item = item;
      this.isCurrentUserForItem =
        this.item?.user!.username === this.rootStore.userStore.user?.username;
      // return item;
      return toJS(item);
    } else {
      this.loadingInitial = true;
      try {
        item = await agent.Items.details(id);
        runInAction(() => {
          this.item = item;
          this.isCurrentUserForItem =
            this.item?.user!.username ===
            this.rootStore.userStore.user?.username;
          this.itemRegistry.set(item.id, item);
          this.loadingInitial = false;
        });
        return item;
      } catch (error) {
        runInAction(() => {
          this.loadingInitial = false;
          console.log(error);
        });
      }
    }
  };

  @action createItem = async (item: IItem, files: Blob[]) => {
    this.submitting = true;
    try {
      item.dateAdded = new Date();
      item.user = this.rootStore.userStore.user!;
      item.photos = [];
      item.comments = [];

      await agent.Items.create(item);

      for (let index = 0; index < files.length; index++) {
        let photo = await agent.Items.uploadItemPhoto(item.id, files[index]);
        item.photos?.push(photo);
        if (photo.isMain) {
          item.image = photo.url;
        }
      }

      runInAction(() => {
        this.itemRegistry.set(item.id, item);
        this.item = item;
        this.submitting = false;
      });

      history.push("/items/" + item.id);
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      console.log(error);
    }
  };

  @action editItem = async (item: IItem) => {
    this.submitting = true;
    try {
      await agent.Items.update(item);
      runInAction(() => {
        this.itemRegistry.set(item.id, item);
        this.item = item;
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });

      console.log(error);
    }
  };

  @action deleteItem = async (
    id: string,
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Items.delete(id);
      runInAction(() => {
        this.itemRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      console.log(error);
    }
  };

  @action uploadItemPhoto = async (itemId: string, file: Blob) => {
    this.uploadingPhoto = true;
    try {
      const photo = await agent.Items.uploadItemPhoto(itemId, file);
      runInAction(() => {
        if (this.item) {
          this.item.photos!.push(photo);
          if (photo.isMain) {
            this.item.image = photo.url;
          }
        }
        this.uploadingPhoto = false;
      });
    } catch (error) {
      console.log(error);
      toast.error("Problem uploading the Photo");
      runInAction(() => {
        this.uploadingPhoto = false;
      });
    }
  };

  @action setMainPhoto = async (photo: IPhoto) => {
    this.loading = true;
    try {
      await agent.Items.setMainItemPhoto(this.item!.id, photo.id);
      runInAction(() => {
        this.item!.photos!.find((a) => a.isMain)!.isMain = false;
        this.item!.photos!.find((a) => a.id === photo.id)!.isMain = true;
        this.item!.image = photo.url;
        this.loading = false;
      });
    } catch (error) {
      toast.error("Problems setting the photo as main.");
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action deletePhoto = async (photo: IPhoto) => {
    this.loading = true;
    try {
      await agent.Items.deleteItemPhoto(this.item!.id, photo.id);
      runInAction(() => {
        this.item!.photos = this.item!.photos!.filter((a) => a.id !== photo.id);
        this.loading = false;
      });
    } catch (error) {
      toast.error("Problems deleting this photo.");
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}

/***********************************************************************************************************************************************
 * Observable: Is a property that when changes it value notify that did change.
 * Computed: Returns a data that is already retrived but processed differently. like Sum in excel, or calculated functions on SQL
 * Observer: The Main Mechanism that will handle the Observable Property when this one changes. It will notify to whomever should be notified.
 * Action: A state changing action that will modify the state of an observable property. Or an action to be taken when a property changes.
 ***********************************************************************************************************************************************/
