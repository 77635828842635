import React from "react";
import { Link } from "react-router-dom";
import { Segment, Button, Divider } from "semantic-ui-react";
import { IItem } from "../../../app/models/item";
import { CarouselProvider, Slide, Slider, Image } from "pure-react-carousel";
import CustomDotGroup from "../../../app/common/photoSlider/customDotGroup";
import { observer } from "mobx-react-lite";

const ItemDetailedHeader: React.FC<{
  item: IItem;
  isCurrentUserForItem: boolean;
}> = ({ item, isCurrentUserForItem }) => {
  return (
    <Segment.Group>
      <Segment attached="top">
        <CarouselProvider
          naturalSlideWidth={1}
          naturalSlideHeight={1}
          totalSlides={!item.photos || item.photos.length === 0 ? 1 : item.photos!.length}
          orientation="horizontal"
          isPlaying={true}
          interval={2000}
          infinite
        >
          {!item.photos || item.photos?.length === 0 ? (
            <Slider>
              <Slide tag="a" index={0} key={0}>
                <Image src={"/assets/giftBox.jpg"} hasMasterSpinner={true} />
              </Slide>
            </Slider>
          ) : (
            <Slider>
              {item.photos!.map((photo, i) => (
                <Slide tag="a" index={i} key={i}>
                  <Image
                    src={photo!.url ?? "/assets/giftBox.jpg"}
                    hasMasterSpinner={true}
                  />
                </Slide>
              ))}
            </Slider>
          )}
          <Divider />
          <CustomDotGroup slides={item.photos? item.photos!.length: 1} />
        </CarouselProvider>
      </Segment>
      {isCurrentUserForItem && (
        <Segment clearing attached="bottom">
          <Button
            as={Link}
            to={`/manage/${item.id}`}
            color="orange"
            floated="right"
            content="Edit Item"
          />
        </Segment>
      )}
    </Segment.Group>
  );
};
export default observer(ItemDetailedHeader);
