import React from "react";
import { combineValidators, isRequired } from "revalidate";
import { IProfile } from "../../app/models/profile";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form } from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { observer } from "mobx-react-lite";
import TextAreaInput from "../../app/common/form/TextAreaInput";

const validate = combineValidators({
  firstName: isRequired("firstName"),
  lastName: isRequired("lastName"),
});

interface IProps {
  updateProfile: (profile: IProfile) => void;
  profile: IProfile;
}

const ProfileEditForm: React.FC<IProps> = ({
  updateProfile,
  profile,
}) => {
  return (
    <FinalForm
      onSubmit={updateProfile}
      validate={validate}
      initialValues={profile!}
      render={({ handleSubmit, invalid, pristine, submitting }) => (
        <Form onSubmit={handleSubmit} error>
          <Field
            name="firstName"
            component={TextInput}
            placeholder="First Name"
            value={profile!.firstName}
          />
          <Field
            name="lastName"
            component={TextInput}
            placeholder="Last Name"
            value={profile!.lastName}
          />
          <Field
            name="bio"
            component={TextAreaInput}
            rows={2}
            placeholder="Biography"
            value={profile!.bio}
          />

          <Field
            name="address1"
            component={TextInput}
            placeholder="Address"
            value={profile!.address1}
          />

          <Field
            name="address2"
            component={TextInput}
            placeholder="Unit/Apt/Suit"
            value={profile!.address2}
          />

          <Field
            name="city"
            component={TextInput}
            placeholder="City"
            value={profile!.city}
          />

          <Field
            name="state"
            component={TextInput}
            placeholder="State"
            value={profile!.state}
          />

          <Field
            name="zip"
            component={TextInput}
            placeholder="Zip Code"
            value={profile!.zip}
          />

          <Field
            name="country"
            component={TextInput}
            placeholder="Country"
            value={profile!.country}
          />

          <Button
            loading={submitting}
            floated="right"
            disabled={invalid || pristine}
            positive
            content="Update Profile"
          />
        </Form>
      )}
    />
  );
};

export default observer(ProfileEditForm);
