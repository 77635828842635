import React, { Fragment, useContext, useEffect } from "react";
import { Container } from "semantic-ui-react";
import Navbar from "../../features/nav/Navbar";
import ItemDashboard from "../../features/items/Dashboard/ItemDashboard";
import { observer } from "mobx-react-lite";
import {
  Route,
  withRouter,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import ItemForm from "../../features/items/form/ItemForm";
import ItemDetails from "../../features/items/Details/ItemDetails";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import { RootStoreContext } from "../stores/rootStore";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import ProfilePage from "../../features/profiles/ProfilePage";
import PrivateRoute from "./PrivateRoute";
import RegisterSuccess from "../../features/user/RegisterSuccess";
import VerifyEmail from "../../features/user/VerifyEmail";

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;

  useEffect(() => {
    if (token && !appLoaded) {
      getUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token, appLoaded]);

  if (!appLoaded) {
    return <LoadingComponent content="Loading Exycle..." />;
  }

  return (
    <Fragment>
      <ModalContainer />
      <ToastContainer position="bottom-right" />
      <Route exact path="/" component={HomePage} />
      <Route
        path={"/(.+)"}
        render={() => (
          <Fragment>
            <Navbar />
            <Container style={{ marginTop: "7em" }}>
              <Switch>
                <PrivateRoute exact path="/items" component={ItemDashboard} />
                <PrivateRoute path="/items/:id" component={ItemDetails} />
                <PrivateRoute
                  key={location.key}
                  path={["/createItem", "/manage/:id"]}
                  component={ItemForm}
                />
                <PrivateRoute path="/profile/:username" component={ProfilePage} />
                <Route path='/user/registerSuccess' component={RegisterSuccess} />
                <Route path='/user/verifyEmail' component={VerifyEmail} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default withRouter(observer(App));
