import React from "react";
import { Grid, Icon, Segment } from "semantic-ui-react";
import { IItem } from "../../../app/models/item";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const ItemDetailedinfo: React.FC<{ item: IItem }> = ({ item }) => {
  return (
    <Segment.Group>
      <Segment attached="top">
        <Grid>
          <Grid.Column width={1}>
            <Icon size="large" color="teal" name="info" />
          </Grid.Column>
          <Grid.Column width={15}>
            <p>{item.description}</p>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment attached>
        <Grid verticalAlign="middle">
          <Grid.Column width={1}>
            <Icon name="calendar" size="large" color="teal" />
          </Grid.Column>
          <Grid.Column width={15}>
            {format(new Date(item.dateAdded), "MM-dd-yyyy")}
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment attached>
        <Grid verticalAlign="middle">
          <Grid.Column width={1}>
            <Icon name="user" size="large" color="teal" />
          </Grid.Column>
          <Grid.Column width={15}>
            <Link to={`/profile/${item.user!.username}`}>
              {item.user!.username}
            </Link>
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

export default ItemDetailedinfo;